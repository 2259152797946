import React from 'react';
import Slider from 'react-slick'
import Link from 'gatsby-link';

import img1 from '../images/photos/zachodSlonca.png';
import img2 from '../images/photos/09-06-dziwnowek.jpg';
import img3 from '../images/photos/AA1.jpg';
import img4 from '../images/photos/A2-1.jpg';
import img5 from '../images/photos/amber3/2-main.jpg'


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000
};


const SliderComponent = () => {
  return (
    <div className="slider__wrapper">

      <Slider {...settings}>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img1})` }}>
            <span>
              <h3>Luksusowe apartamenty nad morzem </h3>
              <Link to="/p/oferta/" className="light" >Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img2})` }}>
            <span>
              <h3>Wakacje w Dziwnówku</h3>
              <Link to="/pl/oferta/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img3})` }}>
            <span className="dark">
              <h3>Styl i wygoda</h3>
              <Link to="/pl/amber1/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img4})` }}>
            <span>
              <h3>Nowocześnie zaaranżowane wnętrze</h3>
              <Link to="/pl/amber2/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img5})` }}>
            <span>
              <h3>Wysoki standard</h3>
              <Link to="/pl/amber3/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
      </Slider>
    </div>
  )
}
export default SliderComponent;



export const SliderComponentEN = () => {
  return (
    <div className="slider__wrapper">
      <Slider {...settings}>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img1})` }}>
            <span>
              <h3>Luxurious apartments at the seaside</h3>
              <Link to="/en/oferta/" className="light" >Find out more</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img2})` }}>
            <span>
              <h3>Holidays in Dziwnówek</h3>
              <Link to="/en/oferta/">Find out more</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img3})` }}>
            <span className="dark">
              <h3>Style and comfort</h3>
              <Link to="/en/amber1/">Find out more</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img4})` }}>
            <span>
              <h3>Modern interior</h3>
              <Link to="/en/amber2/">Find out more</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img5})` }}>
            <span>
              <h3>High standard</h3>
              <Link to="/en/amber3/">Find out more</Link>
            </span>
          </span>
        </div>
      </Slider>
    </div>
  )
}


export const SliderComponentDE = () => {
  return (
    <div className="slider__wrapper">

      <Slider {...settings}>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img1})` }}>
            <span>
              <h3>Luxusappartements am Meer </h3>
              <Link to="/de/oferta/" className="light" >Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img2})` }}>
            <span>
              <h3>Sommerferien in Dziwnówek</h3>
              <Link to="/de/oferta/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img3})` }}>
            <span className="dark">
              <h3>Stil und Funktionalität</h3>
              <Link to="/de/amber1/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img4})` }}>
            <span>
              <h3>Modern eingerichteter Innenraum</h3>
              <Link to="/de/amber2/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
        <div>
          <span className="slide" style={{ backgroundImage: `url(${img5})` }}>
            <span>
              <h3>Wysoki standard</h3>
              <Link to="/de/amber3/">Dowiedz sie wiecej</Link>
            </span>
          </span>
        </div>
      </Slider>
    </div>
  )
}