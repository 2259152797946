import React from 'react';
import Layout from './index';

import messages from '../data/messages/pl';

import '@formatjs/intl-pluralrules/locale-data/pl'


export default (props) => (
  <Layout
    {...props}
    messages={messages}
  />);