import React from 'react';
import Layout from "../layouts/pl.js";
import { FaPhone } from 'react-icons/fa';
import SliderComponent from '../components/SliderComponent';

const IndexPage = (props) => (

  <Layout location={props.location}>
    <div>
      <a className="fixed-phone" href="tel:+48663742379" target="_blank" rel="noreferrer"><FaPhone /></a>
      <section className="slider" style={{ height: '100vh' }} id="section1">
        <SliderComponent />
      </section>
    </div>
  </Layout>
)

export default IndexPage
